<template>
  <div>
    <div v-if="!loading" class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <img :src="mover.logo" style="max-width: 100%; max-height: 100%;" alt="logo">
            <table class="table table-sm table-bordered">
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{{ mover.email }}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{{ mover.phone_number }}</td>
                </tr>
                <tr>
                  <td>Is Promoted</td>
                  <td>{{ mover.promoted ? 'Yes' : 'No' }}</td>
                </tr>
                <tr v-for="(url, site) in mover.socials" :key="`social-${site}`">
                  <td>{{ site }}</td>
                  <td>{{ url }}</td>
                </tr>
                <tr>
                  <td colspan="2" align="center">
                    <router-link :to="{ name: 'admin.movers.edit' }" class="btn btn-primary">Edit</router-link>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" align="center">
                    <a href="#" class="btn btn-danger" @click.prevent="destroy">Delete</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-8">
            <h3>{{ mover.name }}</h3>
            <p>
              <i class="lni lni-map-marker"></i>
              <span class="border px-2 me-2" v-for="(region, i) in mover.regions" :key="`region-${i}`">{{ region.name }}</span>
            </p>
            <div class="description" v-html="mover.description"></div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      mover: {}
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/admin/movers/${this.$route.params.id}`)
        .then((response) => {
          this.mover = response.data.mover;
          this.loading = false;
        });
    },

    destroy() {
      this.$axios.delete(`/api/v1/admin/movers/${this.$route.params.id}`).then(() => {
        this.$router.push({ name: 'admin.movers.index' })
      })
    }
  },
};
</script>
